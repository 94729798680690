import React from "react";
import {Container, Nav, Navbar, NavbarBrand, NavItem} from "reactstrap";
import {Link} from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./../styles/index.scss"


const Layout = ({children}) => {

    const links = [
        {
            url: "/",
            label: "Home"
        },
        {
            url: "/about",
            label: "O mnie"
        },
        {
            url: "/coaching",
            label: "Coaching"
        },
        {
            url: "/school",
            label: "Szkolenia"
        },
        {
            url: "/contact",
            label: "Kontakt"
        },
        {
            url: "/privacy",
            label: "Polityka Prywatności"
        },
        {
            url: "/blog",
            label: "Blog"
        }
    ]

    return <Container className="L__container">
        <Navbar className="L__navbar">
            <NavbarBrand className="L__navbar--brand" href="/">Wojciech Baryga</NavbarBrand>
            <Nav className="L__nav">

                {
                    links.map(({url, label}) => <NavItem className="L__nav--item">
                        <Link className="L__nav--link" to={url}>{label}</Link>
                    </NavItem>)
                }
            </Nav>
        </Navbar>
        {children}
        <div className="F__container">  {
            links.map(({url, label}) => <NavItem className="F__item">
                <Link className="F__link" to={url}>{label}</Link>
            </NavItem>)
        }</div>
    </Container>
}

export default Layout;
